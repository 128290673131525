import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem, ProgressStatus } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { getFileExtension, trimFileExtension } from "../../utils";

export const useOpenFile = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslate(["Global"]);

  const openFile = async (url: string, fileFullPath: string) => {
    if (Capacitor.isNativePlatform()) {
      let removeListener: (() => Promise<void>) | null = null;
      try {
        const { remove } = await Filesystem.addListener(
          "progress",
          (progress: ProgressStatus) => {
            const el = document.querySelector("#msys-file-download-percent");
            const percent =
              progress.contentLength > 0
                ? Math.floor(progress.bytes / progress.contentLength)
                : 0;
            if (el) el.innerHTML = ` ${percent}%`;
          }
        );
        removeListener = remove;

        enqueueSnackbar(
          <div>
            {t("Downloading...", { ns: "Global" })}
            <span id="msys-file-download-percent"></span>
          </div>,
          {
            variant: "default",
            key: "msys-file-download",
            persist: true,
          }
        );

        const result = await Filesystem.downloadFile({
          url,
          path: fileFullPath,
          recursive: true,
          progress: true,
          directory: Directory.Cache,
        });

        if (!result.path)
          throw new Error("Download failed, please try again later");

        const fileLocalPath = result.path.startsWith("file://")
          ? result.path
          : `file://${result.path}`;

        try {
          await Share.share({
            url: fileLocalPath,
          });
        } catch (e) {
          // do nothing with close share dialog
        }
      } catch (e) {
        // if (e instanceof Error)
        //   enqueueSnackbar(e.message, { variant: "error" });
        // fallback
        try {
          await Browser.open({ url, presentationStyle: "fullscreen" });
        } catch (e) {
          const tab = window.open(url, "_system");
          if (tab) tab.focus();
        }
      } finally {
        closeSnackbar("msys-file-download");
        await removeListener?.();
      }
    } else {
      const tab = window.open(url, "_blank");
      if (tab) tab.focus();
    }
  };

  const openPdf = async (url: string, fileName: string) => {
    return openFile(
      url,
      `${trimFileExtension(fileName)}_${moment().format(
        "YYYY-MM-DD_HH-mm-ss"
      )}.pdf`
    );
  };
  const openAnyFile = async (url: string, fileName: string) => {
    return openFile(
      url,
      `${trimFileExtension(fileName)}_${moment().format(
        "YYYY-MM-DD_HH-mm-ss"
      )}.${getFileExtension(fileName)}`
    );
  };

  const urlsRef = React.useRef<string[]>([]);

  const createAndOpenPdf = async (file: File): Promise<void> => {
    const url = URL.createObjectURL(file);
    await openPdf(url, file.name || "file.pdf");
    urlsRef.current.push(url);
  };

  const createAndOpenAnyFile = async (file: File): Promise<void> => {
    const url = URL.createObjectURL(file);
    await openAnyFile(url, file.name);
    urlsRef.current.push(url);
  };

  // revoke created object urls
  React.useEffect(() => {
    return () => {
      const urls = urlsRef.current;
      if (urls.length > 0) {
        urls.forEach(url => {
          try {
            URL.revokeObjectURL(url);
          } catch (e) {
            // nothing to do
          }
        });
      }
    };
  }, []);

  // manually revoke urls
  const revokeUrls = () => {
    const urls = urlsRef.current;
    if (urls.length > 0) {
      urls.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (e) {
          // nothing to do
        }
      });
    }
  };

  return {
    openPdf,
    createAndOpenPdf,
    openAnyFile,
    createAndOpenAnyFile,
    revokeUrls,
  };
};
